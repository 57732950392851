module.exports.vaccines = [
  {
    key: "JN",
    type: "新冠疫苗",
    label: "新冠疫苗<br>莫德納JN.1",
    code: "CoV_Moderna_JN",
  },
  {
    key: "JNK",
    type: "新冠疫苗(兒童)",
    label: "新冠疫苗<br>兒童莫德納JN.1",
    code: "CoV_Moderna_JN",
  },
  {
    key: "JN-S",
    type: "新冠疫苗",
    label: "新冠疫苗<br>特殊族群JN.1",
    code: "CoV_Moderna_JN",
  },
  {
    key: "Flu",
    type: "流感疫苗",
    label: "流感疫苗<br>一般民眾",
  },
  {
    key: "Flu-S",
    type: "流感疫苗",
    label: "流感疫苗<br>特殊族群",
    code: "CoV_Moderna_JN",
  },
  // {
  //   key: "13PCV",
  //   type: "肺炎鏈球菌疫苗(第一劑)",
  //   label: "肺炎鏈球菌疫苗<br>第一劑13PCV",
  // },
  // {
  //   key: "PPV",
  //   type: "肺炎鏈球菌疫苗(第二劑)",
  //   label: "肺炎鏈球菌疫苗<br>第二劑23PPV",
  // },
  // {
  //   key: "MPV",
  //   type: "M痘疫苗",
  //   label: "M痘疫苗",
  // },
];

module.exports.specialGroups = [
  {
    label: "55歲以上原住民",
    document: "戶籍謄本或戶口名簿",
  },
  {
    label: "孕婦及 6 個月內嬰兒之父母",
    document: "媽媽手冊、兒童健康手冊",
  },
  {
    label: "幼兒園托育人員托育機構專業人員及居家托育人員",
    document: "教保員證明文件保母證書、相關單位在職證明",
  },
  {
    label: "醫事人員",
    document: "醫事人員執照",
  },
  {
    label: "非醫事人員-醫院編制內非醫事人員",
    document: "在職證明或員工證",
  },
  {
    label: "非醫事人員-醫事實習學生",
    document: "實習證",
  },
  {
    label: "非醫事人員-衛生保健志工",
    document: "志願服務紀錄冊",
  },
  {
    label: "診所非行政人員",
    document: "診所勞健保納保紀錄",
  },
  {
    label: "防疫人員",
    document: "在職證明或員工證",
  },
  {
    label: "畜牧業",
    document: "在職證明或員工證",
  },
  {
    label: "機構照顧者",
    document: "在職證明或員工證",
  },
  {
    label: "被照顧者",
    document: "機構開立證明或其他佐證資料",
  },
];
